import React from "react";
import { Col, Row } from "react-bootstrap";
import USPImg from "../../assets/images/Livasa_USP.png";
import Icon1 from "../../assets/images/USPIcons/check-mark.png";
import Icon2 from "../../assets/images/USPIcons/loupe.png";
import Icon3 from "../../assets/images/USPIcons/no-preservatives.png";
import Icon4 from "../../assets/images/USPIcons/ecology.png";
import Icon5 from "../../assets/images/USPIcons/moisture-wicking-fabric.png";
import Icon6 from "../../assets/images/USPIcons/organic.png";
import USPSection2Cards from "./USPSection2Cards";
import "./USPSection2.css";

export default function USPSection2() {
  const USP = [
    {
      image: Icon1,
      title: "Farm 2 Fork Traceability",
      bodycopy:
        "Farm-to-Fork traceability is ensured for our honey products, providing transparency and quality assurance.",
    },
    {
      image: Icon2,
      title: "Origin Authenticity",
      bodycopy:
        "Livasa ensures the origin authenticity of its honey through traceability and unwavering commitment to quality.        ",
    },
    {
      image: Icon3,
      title: "Chemical Preservative free honey",
      bodycopy:
        "Livasa offers chemical preservative-free honey, ensuring pure and natural goodness in every drop.        ",
    },
    {
      image: Icon4,
      title: "Sustainably Produced",
      bodycopy:
        "Livasa's honey is produced sustainably through practices such as organic beekeeping, habitat preservation, and minimal hive disturbance.        ",
    },
    {
      image: Icon5,
      title: "Moisture free honey ",
      bodycopy:
        "Our honey is carefully processed to be moisture-free, ensuring superior quality and extended shelf life for customers.        ",
    },

    {
      image: Icon6,
      title: "Organic honey ",
      bodycopy:
        "Experience the pure and natural goodness of our organic honey, sourced and produced with utmost care.        ",
    },
  ];

  return (
    <>
      <div className="honeycomb"></div>
      <div className="container">
        <Row className="justify-content-between align-items-center">
          <Col md={5}>
            <img src={USPImg} className="w-100" alt="Icon" />
          </Col>
          <Col md={7}>
            <Row>
              {USP.map((e, index) => {
                return (
                  <Col md={6} key={index}>
                    <USPSection2Cards
                      cardtitle={e.title}
                      src={e.image}
                      cardBodycopy={e.bodycopy}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
