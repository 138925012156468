import React from "react";
import Icon1 from "../../assets/images/Services/white-label1.jpg";
import Icon3 from "../../assets/images/Services/young-beekeeper-man-clean-wooden-honey-frame-working-apiary-summer-day.jpg";
import "./ServiceSection1.css";
import AboutSectionSubsection1 from "../AboutSection1/AboutSectionSubsection1";

export default function ServiceSection1() {
  const services = [
    {
      image: Icon1,
      id: "WhiteLabelling",
      title: "White Labelling",
      bodycopy:
        "Livasa is a trusted contract manufacturer specializing in honey products. With our extensive experience and expertise, we offer reliable and high-quality manufacturing solutions for honey-based products through GBR Farming ecosystem. As a contract manufacturer, we work closely with our clients to understand their unique requirements and deliver customized honey formulations that meet their specifications. Our state-of-the-art facilities and stringent quality control processes ensure that every batch of honey product we manufacture adheres to the highest industry standards. Whether you need private label honey products or bulk manufacturing, Livasa is your reliable partner for exceptional quality and timely delivery. Trust us to bring your honey product ideas to life with our comprehensive contract manufacturing services.",
    },

    // {
    //   rowreverse: "true",
    //   image: Icon2,
    //   id: "Trade",
    //   title: "Trade",
    //   bodycopy:
    //     "Livasa offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your",
    // },
    {
      rowreverse: "true",
      image: Icon3,
      id: "FieldProduction",
      title: "Field Production",
      bodycopy:
        "We partner with esteemed organizations like GBR Farming Private Limited to ensure the well-being and productivity of honeybees. Livasa is a trusted company dedicated to the care and management of honey colonies provided through GBR Farming. With a deep understanding of the intricate needs of these vital pollinators, Livasa employs expert beekeepers who provide diligent care, ensuring the health, growth, and sustainability of honey colonies. Through our commitment to excellence, Livasa ensures that our trusted partners receive high-quality honey products that meet the highest standards of taste, purity, and traceability. Together, we are dedicated to supporting the honey industry while promoting environmental stewardship and preserving the invaluable role of honeybees in our ecosystem.",
    },
  ];
  return (
    <div className="ServiceSectionContainer container ">
      {services.map((e) => {
        return (
          <AboutSectionSubsection1
          id={e.id}
          src={e.image}
          bodycopy={e.bodycopy}
          title={e.title}
          rowreverse={e.rowreverse}
        />
        );
      })}
    </div>
  );
}
