// import * as Yup from "yup";
// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// export const contactSchema = Yup.object({
//   name: Yup.string().min(4).max(40),
//   lastname: Yup.string().min(1).max(40),
//   email: Yup.string().email().required("Please enter your email"),
//   number: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
//   Comment: Yup.string(),
//   password: Yup.string().min(10).max(20).required("Password is required"),
// });


import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const textReg = /^[a-zA-Z]+$/;

export const RegisterSchema = Yup.object({
  fname: Yup.string()
  .matches(textReg, "First Name should only contain alphabets")
  .min(4, "First Name must be at least 4 characters")
  .max(30, "First Name must be at most 30 characters")
  .required("Please enter your first name"),

lname: Yup.string()
  .matches(textReg, "Last Name should only contain alphabets")
  .min(1, "Last Name must be at least 1 character")
  .max(30, "Last Name must be at most 30 characters")
  .required("Please enter your last name"),

  email:Yup.string().email("Please enter valid email").required("Please enter your email"),
  phone:Yup.string().matches(phoneRegExp,"Phone number is not valid").required("Please enter your number"),
  usertype: Yup.string()
    .oneOf(
      ["FOOD-PROCESSOR", "Retailer"], 
      "Please select a valid user type" 
    )
    .required("Please select your user type"), 
})