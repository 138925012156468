import React from "react";
import Mission from "../../assets/images/livasa-about/our-missin.jpg";
import Vision from "../../assets/images/livasa-about/our-vision.jpg";
import Product from "../../assets/images/livasa-about/our-products.jpg";
import "./AboutSection1.css";
import AboutSectionSubsection1 from "./AboutSectionSubsection1";
export default function AboutSection1() {
  const aboutContent = [
    {
      image: Mission,
      title: "Mission",
      bodycopy:
        "To be the leading distributor of organic and natural honey, offering retailers a premium product that is sustainably sourced, unprocessed, and free from additives. We are dedicated to promoting the health benefits of pure honey while supporting ethical farming practices, fostering strong relationships with beekeepers, and contributing to the well-being of consumers.",
    },
    {
      rowreverse: "true",
      image: Vision,
      title: "Vision",
      bodycopy:
        "To create a world where every individual can enjoy the purest, most natural honey, sustainably sourced from healthy ecosystems. We envision becoming the trusted partner for retailers worldwide, advancing the accessibility of organic honey, and leading the industry in environmental responsibility and product authenticity.",
    // bodycopy2:"true"
      },
    {
      image: Product,
      title: "Our Products",
      bodycopy:
        "Livasa offers a wide range of honey and honey-based products that cater to diverse preferences and needs. Our collection includes a variety of exquisite honey types, carefully harvested from our thriving colonies, each with its own distinct flavor profile and nutritional benefits. In addition to pure honey, we also provide innovative honey-infused products, such as honey shampoo and soap. These products harness the natural properties of honey to nourish and revitalize your hair and skin, offering a luxurious and indulgent experience. At Livasa, we take pride in delivering exceptional honey and honey products that promote overall well-being and enhance your daily self-care rituals.",
    button: true,
    href:"/collections/products/Processed-Honey"
      },
  ];
  return (
    <>
      {aboutContent.map((e,index) => {
        return (
          <AboutSectionSubsection1
          key={index}
            src={e.image}
            bodycopy={e.bodycopy}
            title={e.title}
            rowreverse={e.rowreverse}
            button={e.button}
            href={e.href}
          />
        );
      })}
    </>
  );
}
