import React from "react";
import "./InputField.css";

export default function SelectField(props) {
  const { value, onChange, options, id, placeholder } = props; // Destructure props here

  return (
    <div
      className={`${props.classname} input-container mb-2`}
      id={props.checkval}
    >
      {/* <label className={props.value && 'filled'} htmlFor={props.htmlFor} id={props.idval}>
                  {props.label}
                </label> */}
      <select
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)} // Pass the selected value directly
        placeholder={placeholder}
      >
        <option value="" disabled selected>
          {placeholder}
        </option>{" "}
        Handle placeholder if provided
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
