import React from "react";
import "./ReviewContainer.css";
export default function ReviewContainer({
  src,
  ratings,
  reviews,
  description,
}) {
  return (
    <div className="review-container">
      <img className="mx-auto d-block mb-4" src={src} alt="Reviwer-Img "/>
      <div className="review-sub-container text-center">
        <span className="mb-3 d-block">{ratings}</span>
        <p className="mb-2">{reviews}</p>
        <p className="font-weight-bold">{description}</p>
      </div>
    </div>
  );
}
