import axios from "axios";
import "../config.js";

const getAccess = () => {
// const existingToken = localStorage.getItem("token")
//   if (existingToken) {
//     return Promise.resolve(existingToken); 
//   }

  var clientkeys = {};
  if (`${process.env.REACT_APP_DEVELOPMENT_ENV}` === "staging") {
    clientkeys = {
      client_name: `${process.env.REACT_APP_NAME_STAGING}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET_STAGING}`,
    };
  } else if (`${process.env.REACT_APP_DEVELOPMENT_ENV}` === "production") {
    clientkeys = {
      client_name: `${process.env.REACT_APP_NAME_PRODUCTION}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET_PRODUCTION}`,
    };
  }
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${window.livasaConfig.token}${window.livasaConfig.networkid}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(clientkeys),
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res);
        }
        localStorage.setItem("token", res.data.access_token);
        return resolve(res.data.access_token);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

const getToken = () => {
  try {
    let data = localStorage.getItem("token");
    return data;
  } catch (e) {
    return e;
  }
};

const getCategoriesBasedOnParentId = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); // Get token from localStorage
      if(!token){
        await getToken()
      }
      if (!token) {
        token = await getAccess(); // Call getAccess to get a new token if necessary
      }

      const res = await axios({
        method: "GET",
        url: `${window.livasaConfig.getCategories}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status !== 200) {
        reject(new Error("Failed to fetch categories"));
      } else {
        resolve(res.data.categoryList);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const productModelBasedonSubcategory = (getCatId, Type, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); // Get token from localStorage
      if (!token) {
        token = await getAccess(); // Call getAccess to get a new token if necessary
      }      const res = await axios({
        method: "GET",
        url: `${window.livasaConfig.modelBasedOnCategory}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}/${user}/${getCatId}/${Type}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          orgid: user,
          networkid: `${window.livasaConfig.networkid}`,
          username: user,
          suborgid: "",
        },
      });

      if (res.status !== 200) {
        return reject(res);
      }
      return resolve(res.data.products);
    } catch (error) {
      reject(error);
    }
  });
};

const productLikeCount = (product) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",

      url: `${window.livasaConfig.productLikeCount}${window.livasaConfig.networkid}/${product.consumeradminid}/${product.businessid}/${product.productid}`,

      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        networkid: `${window.livasaConfig.networkid}`,
        orgid: `${product.businessid}`,
        suborgid: "",
        username: `${product.businessid}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

const getSubCategories = (username, catid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); // Get token from localStorage
      if (!token) {
        token = await getAccess(); // Call getAccess to get a new token if necessary
      }      const res = await axios({
        method: "GET",
        url: `${window.livasaConfig.getSubCategories}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}/${username}/${catid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status !== 200) {
        return reject(res.data);
      }
      return resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

const getStoreLocation = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); // Get token from localStorage
      if (!token) {
        token = await getAccess(); // Call getAccess to get a new token if necessary
      }      let url = `${window.livasaConfig.getLoaction}${window.livasaConfig.networkid}/${window.livasaConfig.user}/${type}`;
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res !== 200) {
        return reject(res.data);
      }
      return resolve(res.data);
    } catch (err) {
      console.log("jjj");
    }
  });
};

const getAllProducts = (catId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); // Get token from localStorage
      if (!token) {
        token = await getAccess(); // Call getAccess to get a new token if necessary
      }
      const url = `${window.livasaConfig.getAllProducts}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}/${window.livasaConfig.user}/${catId}`;
      const res = await axios({
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          networkid: `${window.livasaConfig.networkid}`,
          orgid: `${window.livasaConfig.user}`,
          suborgid: "",
          username: `${window.livasaConfig.user}`,
        },
      });

      if (res.status !== 200) {
        reject(res.data.products);
      }else{
        resolve(res.data.products);
      }
      
    } catch (error) {
      console.log(error);
    }
  });
};



const doSignup = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${window.livasaConfig.registerUser}${window.livasaConfig.networkid}/${process.env.REACT_APP_NAME_STAGING}/${process.env.REACT_APP_CLIENT_SECRET_STAGING}`,
      data: data,
    })
      .then((res) => {
        if (res.status !== 200) {
          console.error(res.data);
          return reject(res.data.message);
        }
        return resolve(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          return reject("Account already exists.");
        } else {
          console.error(e);
          return reject("Internal server error, please try again.");
        }
      });
  });
};

export default {
  getAccess,
  getCategoriesBasedOnParentId,
  productModelBasedonSubcategory,
  productLikeCount,
  getSubCategories,
  getStoreLocation,
  getAllProducts,
  doSignup
};
