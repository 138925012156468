import React from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function AboutSectionSubsection1({
  src,
  title,
  bodycopy,
  rowreverse,
  id,
  button,
  href
}) {
  return (
    <Row
      id={id}
      className={`${
        rowreverse
          ? "rowreverse align-items-center justify-content-between about-container rowreversemob"
          : "rowreversemob align-items-center justify-content-between about-container"
      } `}
    >
      <Col md={7}>
        <div className="mt-md-0 mt-3">
          <h3 className="title mb-3">{title}</h3>
          <p className="mb-2">{bodycopy} </p>

          {button && <NavLink className="about-us-btn mt-3 d-inline-block" to={href}>Explore</NavLink>}
        </div>
      </Col>
      <Col md={4}>
        <img src={src} className="w-100" alt="about-images" />
      </Col>
    </Row>
  );
}
