import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Home from "./views/Home/Home";
import Collection from "./views/collection/Collection";
import TermsCondition from "./views/termscondition/TermsCondition";

import PrivacyPolicy from "./views/privacyPolicy/PrivacyPolicy";
import About from "./views/about/About";
import USP from "./views/usp/USP";

import StoreLocator from "./views/StoreLocator/StoreLocator";
import Honey from "./views/honey/Honey";
import Services from "./views/Services/Services";
import MainService from "./common/MainService";
import { useEffect } from "react";
import Registration from "./views/Registration/Registration";
function App() {
  useEffect(() => {
    accessToken();
  }, []);

  const accessToken = () => {
     MainService.getAccess()
      .then((res) => {
      })
      .catch((err) => {
        console.log("errrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", err);
      });
  };




  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/collections" element={<Collection />}>
          <Route path="products/:subId" element={<Honey />} />
       
        </Route>
       
        <Route path="/terms-conditions" element={<TermsCondition />} />
    
        <Route path="cookie-privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/our-usp" element={<USP />} />

        <Route exact path="/services" element={<Services />} />

   
        <Route path="/location" element={<StoreLocator />} />
        <Route path="/register" element={<Registration />} />

      </Routes>
    </>
  );
}

export default App;
