import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { NavLink, Link } from "react-router-dom";
import "./Footer.css";
import { BsInstagram,BsLinkedin} from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import {AiFillTwitterSquare} from "react-icons/ai";
import {IoLogoYoutube} from "react-icons/io"
export default function Footer() {
  return (
    <div>
      <hr className="footer-hr mb-5"></hr>

      <div className="container footer-container">
        <Row className="justify-content-center">
          <Col md={4} lg={2} sm={6} xs={6} className="mb-md-0 mb-3">
            <h3 className="mb-md-4 mb-3">Our Story</h3>
            <ul className="list-unstyled">
              <li>
                <NavLink className=" mb-2 d-inline-block" to="/about-us">
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink className=" mb-2 d-inline-block" to="/location">
                  Store Location
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className=" mb-2 d-inline-block"
                  to="/for-farmers"
                >
                  For Farmers
                </NavLink>
              </li> */}

              {/* <li>
                <NavLink
                  className=" mb-2 d-inline-block"
                  to="/for-manufacturers"
                >
                  For Manufacturers
                </NavLink>
              </li> */}
            </ul>
          </Col>
   
       

          <Col md={4} lg={2} sm={6} xs={6} className="mb-md-0 mb-3">
            <h3 className="mb-md-4 mb-3">Quick links</h3>
            <ul className="list-unstyled">
              {/* <li>
                <NavLink
                  className="d-inline-block  mb-2"
                  to="/terms-conditions"
                >
                  Money-making assets 
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="d-inline-block  mb-2"
                  to="/terms-conditions"
                >
                  Terms and Conditions
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="d-inline-block  mb-2"
                  to="/cookie-privacy-policy"
                >
                  Cookie & Privacy Policy
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className="d-inline-block  mb-2"
                  to="/return-refund-policy"
                >
                  Return & Refund Policy
                </NavLink>
              </li> */}
            </ul>
          </Col>

          <Col lg={8} md={8}>
            <h3 className="mb-md-4 mb-3">Contact</h3>
<Row>
  <Col md={5}>
            <p className="mb-1">
              <strong>Head Office:</strong>
            </p>
            <p className="mb-md-3 mb-4">
              GF-117, Gour City center, Sector-4, near gour city mall, Greater
              Noida extenstion, Uttar-Pradesh 
              <a className="d-inline-block me-2" href="tel:+91-8800329248" rel="noreferrer" >
                +91-8800329248
              </a>
            </p>
          
            </Col>
            <Col md={5}>
            <p className="mb-1">
              <strong>Marketing Office:</strong>
            </p>
            <p className="mb-md-3 mb-4">
              No 119/1, Rajanna layout, Agara, Horamavu, Bangalore 43. 
              <a className="d-inline-block me-2" href="tel:+91-9886672380" rel="noreferrer" >
                +91-9886672380
              </a>
            </p>
            {/* <p className="mb-1">
                  <strong>Customer Care:</strong>
                </p>
                <p className="mb-4"> */}
            {/* <a className="d-inline-block me-2" href="tel:+91-8800329248">
                    +91-8800329248,
                  </a> */}
            {/* <a className="d-inline-block  me-2" href="tel:+91-9717133818">
                    +91-9717133818,
                  </a>
                  <a className="d-inline-block" href="tel:+91-1143728839">
                    +91-1143728839
                  </a> */}
            {/* </p> */}
            <p>
              <strong>Email: </strong>
              <a href="mailto:hello@livasanatural.com" rel="noreferrer" >
                hello@livasanatural.com
              </a>
            </p>
            </Col>
            </Row>
          </Col>

        </Row>

        <div className=" mb-5 mt-5 d-md-block d-none">
          <a className="social-media-icons" href="https://www.facebook.com/profile.php?id=100094453486891" target="_blank" rel="noreferrer">
            <FaFacebookSquare className="me-3" />
          </a>

          <a
          className="social-media-icons"
            href="https://www.instagram.com/livasanatural/"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram className="me-3"  />
          </a>
  
          <a
          className="social-media-icons"
            href="https://www.linkedin.com/company/livasa-natural/"
           target="_blank"
           rel="noreferrer"
          >
            <BsLinkedin  className="me-3" />
          </a> 

          <a
          className="twitter"
            href="https://twitter.com/LivasaF"
           target="_blank"
           rel="noreferrer"
          >
            <AiFillTwitterSquare  className="me-3 twitter" />
          </a> 
          <a
          className="youtube"
            href="https://youtube.com/@livasanatural72?si=OyboV_F2rNhd3ciE"
           target="_blank"
           rel="noreferrer" 
          >
            <IoLogoYoutube  />
          </a> 
          

          
          
        </div>
      </div>
      <hr className="footer-hr"></hr>
      <div className="copyright-container d-flex justify-content-center">
        <p>
          © 2023, <Link to="/">livasanatural</Link> | All Right Reserved
        </p>
      </div>
    </div>
  );
}
