import React, { useState, useEffect, useMemo } from "react";
import RawHoney from "../../assets/images/honey-wooden-table-min.jpg";
import ProcessedHoney from "../../assets/images/honey.jpg";
import SemiProcessedHoney from "../../assets/images/semi-processed-honey.jpg";
import Title from "../../components/titles/Title";
import FilterSort from "../../components/FilterSort/FilterSort";
import { Circles } from "react-loader-spinner";

import { useParams } from "react-router-dom";
import MainService from "../../common/MainService";
import { NavHashLink } from "react-router-hash-link";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useCallback } from "react";
export default function Honey() {
  const { subId } = useParams();
  // const [getCatId, setCatId] = useState("");
  const [loding, setLoading] = useState(true);
  const [getHoneyProducts, setHoneyProducts] = useState([]);

  const [subCategory, setSubCategory] = useState([]);
  const [getSubCategoryValue, setSubCategoryValue] = useState("");

  useMemo(() => {
    if (subId !== "null") {
      setSubCategoryValue(subId);
    }
  }, [subId]);

  // useEffect(() => {
  //   getAllSubCategory();
  // }, []);
  const user = `${window.livasaConfig.user}`;

  const getProductsHandler = useCallback(() => {
    MainService.productModelBasedonSubcategory(
      `${window.livasaConfig.categoryId}`,
      getSubCategoryValue,
      user
    )
      .then((res) => {
        setHoneyProducts(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, [getSubCategoryValue, user]);

  const getAllSubCategory = useCallback(() => {
    MainService.getSubCategories(user, `${window.livasaConfig.categoryId}`)
      .then((res) => {
        setSubCategory(res.contentsubcategories);
        if (!subId) {
          setSubCategoryValue(
            res.contentsubcategories[0].contentsubcategoryname
          );
        }
      })
      .catch((e) => {
        console.log("err");
      });
  }, [user, subId]);
  useEffect(() => {
    // if (!getCatId || getCatId === "" || getSubCategoryValue === "") return;
    getProductsHandler();
    getAllSubCategory();
  }, [getSubCategoryValue, getProductsHandler, getAllSubCategory]);

  // const getCategoriesHandler = () => {
  //   MainService.getCategoriesBasedOnParentId()
  //     .then((res) => {
  //       const catFilter = res.filter((resdata, index) => {
  //         return resdata.categorytypeid === "AGR11";
  //       });
  //       setCatId(catFilter[0].categoryid);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useMemo(() => {
  //   getCategoriesHandler();
  // }, []);

  // const subCatHandler = (val) => {
  //   setSubCategoryValue(val);
  // };

  const subcategoryImageMapping = {
    "Processed-Honey": ProcessedHoney,
    "Semi-Processed-Honey": SemiProcessedHoney,
    "Raw-Honey": RawHoney,
  };
  const defaultImageUrl = RawHoney;
  return (
    <>
      <div className="container collection-container">
        <Title heading="Collections" classname="mb-4" />
        <Row className="">
          {loding === false ? (
            <>
              {subCategory.map((data, index) => {
                const imageUrl =
                  subcategoryImageMapping[data.contentsubcategoryname] ||
                  defaultImageUrl;
                return (
                  <Col md={6} lg={3} key={index}>
                    <NavHashLink
                      key={data.contentsubcategoryname}
                      to={`/collections/products/${data.contentsubcategoryname}/#products`}
                      onClick={() => {
                        setSubCategoryValue(data.contentsubcategoryname);
                      }}
                      className={({ isActive }) =>
                        isActive ? "active-link" : ""
                      }
                    >
                      <div className="card-container">
                        <div className="card-image-container mb-4">
                          <img
                            src={imageUrl}
                            alt={data.contentsubcategoryname}
                          />
                        </div>
                        <div>
                          <h5 className="text-center">
                            {data.contentsubcategoryname}
                          </h5>
                        </div>
                      </div>
                    </NavHashLink>
                  </Col>
                );
              })}
            </>
          ) : (
            <div className="justify-content-center d-flex">
              <Circles
                type="TailSpin"
                color="orange"
                height={70}
                width={70}
                timeout={5000}
              />
            </div>
          )}
        </Row>
        <div className="pt-5" id="products"></div>
        <Title heading="Products" classname="mb-5 pt-5" />
        <FilterSort obj={getHoneyProducts} loadValue={loding} />
      </div>
    </>
  );
}
