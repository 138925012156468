import React from "react";
import InputField from "../../components/Inputfields/InputField";
import { useFormik } from "formik";
import { RegisterSchema } from "../../schemas";
import { RiErrorWarningFill } from "react-icons/ri";
import Button from "../../components/Inputfields/Button";
import SelectField from "../../components/Inputfields/SelectField";
import MainService from "../../common/MainService";
import { Container } from "react-bootstrap";
import Title from "../../components/titles/Title";
const Registration = () => {
  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    usertype: "", // Initialize with an empty string
  };

  const { values, touched, handleChange, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: RegisterSchema,

    onSubmit: () => {
      let _data = {
        source: values.email,
        destination: "siapischain",
        username: values.email,
        firstname: values.fname,
        lastname: values.lname,
        usertype: values.usertype, 
        email: values.email,
        phone: values.phone.toString(),
        requesttype: "accountCreation",
        Role: "NULL",
        accountIs: "Global",
        domain:"siapischain"
      };
      MainService.doSignup(_data).then((res) => {
        console.log(res);
      }).catch((error)=>{
        console.log(error)
      })
    },
  });

  const userType = [
    { value: "FOOD-PROCESSOR", label: "Manufacturer" },
    { value: "Retailer", label: "Retailer" },
  ];

  // Custom handler for SelectField's change
  const handleSelectChange = (selectedOption) => {
    setFieldValue("usertype", selectedOption.value); // Use Formik's setFieldValue to update the usertype
  };

  return (
    <Container>
    <div className="loginregister-container">
    <Title classname="text-center mb-4" heading="Create account" />

            <form onSubmit={handleSubmit} className="loginForm-container">

      <SelectField
        value={values.usertype}
        label="Usertype"
        placeholder="Select usertype"
        onChange={handleSelectChange} // This should trigger the `handleSelectChange` function
        id="usertype"
        options={userType}
        // placeholder="Select user type"
      />
      {errors.usertype && touched.usertype ? (
        <p className="mt-1 mb-0">
          <RiErrorWarningFill /> {errors.usertype}
        </p>
      ) : (
        ""
      )}

      <InputField
        type="text"
        label="First Name"
        id="fname"
        value={values.fname}
        onChange={handleChange}
      />
      {errors.fname && touched.fname ? (
        <p className="mt-1 mb-0">
          <RiErrorWarningFill /> {errors.fname}
        </p>
      ) : (
        ""
      )}

      <InputField
        type="text"
        label="Last Name"
        id="lname"
        value={values.lname}
        onChange={handleChange}
      />
      {errors.lname && touched.lname ? (
        <p className="mt-1 mb-0">
          <RiErrorWarningFill /> {errors.lname}
        </p>
      ) : (
        ""
      )}

      <InputField
        type="email"
        label="Email"
        id="email"
        value={values.email}
        onChange={handleChange}
      />
      {errors.email && touched.email ? (
        <p className="mt-1 mb-0">
          <RiErrorWarningFill /> {errors.email}
        </p>
      ) : (
        ""
      )}

      <InputField
        type="number"
        label="Phone Number"
        id="phone"
        value={values.phone}
        onChange={handleChange}
      />
      {errors.phone && touched.phone ? (
        <p className="mt-1 mb-0">
          <RiErrorWarningFill /> {errors.phone}
        </p>
      ) : (
        ""
      )}

      <Button classname="mx-auto d-block" buttonText="Create " type="submit" />
    </form>
    </div>
    </Container>
  );
};

export default Registration;
