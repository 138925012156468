import React, { useCallback, useEffect, useState } from "react";
import "./Home.css";
import Banner from "../../components/banner/Banner";
import Title from "../../components/titles/Title";
import Benefits from "../../assets/images/Benefits.jpg";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Products from "../../components/products/Products";
import MainService from "../../common/MainService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Circles } from "react-loader-spinner";
import RawHoney from "../../assets/images/honey-wooden-table-min.jpg";
import ProcessedHoney from "../../assets/images/honey.jpg";
import SemiProcessedHoney from "../../assets/images/semi-processed-honey.jpg";
// import CollectionContainer from "../../components/collectionContainer/CollectionContainer";
import user1 from "../../assets/images/user_01.png";
import user2 from "../../assets/images/user_02.png";
import user3 from "../../assets/images/user_03.png";
import ReviewContainer from "../../components/reviewcontainer/ReviewContainer";
import { NavLink } from "react-router-dom";
export default function Home() {
  const clients = [
    "Himachal Organic Bee Products",
    "Pashmina Ladhaki",
    "Khadi Natural Care",
    "SPGL Industries pvt.ltd",
    "Glister Food Pvt.ltd",
    "Insight Food Pvt.ltd",
    "Umang Aayurveda",
    "Kisan and sons",
    "Meezan Enterprises",
    "Safa Honey",
    "Safiya Food Company",
    "Connedit Business Solution Pvt.ltd",
  ];
  // const reviewData = [
  //   {
  //     image: user1,
  //     review:
  //       "I am using Livasa Shilajit from last 6 Months. I can see the Imapct of it on My Daily Routine and Sexual Power Both.",
  //     description: "Naresh Khanna - Age 44 - West Mumbai",
  //     ratings: "★★★★★",
  //   },
  //   {
  //     image: user2,
  //     review:
  //       "Me and my Husband are using the Livasa Kesar for last 3 months with Milk Every night for the better Health Being and Lifestyle.",
  //     description: "Narmata - Age - 32 - Delhi",
  //     ratings: "★★★★★",
  //   },
  //   {
  //     image: user3,
  //     review:
  //       "Livasa Jamun Honey and Neem Honey are the Best Switch over alternates for the Stage 1 and Stage 2 Diabetic Patient",
  //     description: "Amrit - Age 41 - Chandigarh",
  //     ratings: "★★★★☆",
  //   },
  // ];
  // const accessToken = () => {
  //   MainService.getAccess()
  //     .then((res) => {})
  //     // .then(() => {
  //     //   getCategoriesHandler();
  //     // })
  //     .catch((err) => {
  //       console.log("Error", err);
  //     });
  // };

  // useEffect(() => {
  //   accessToken();
  // }, []);

  const [getHoneyProducts, setHoneyProducts] = useState([]);
  const [loding, setLoading] = useState(true);
  const [subCategory, setSubCategory] = useState([]);

  // const getCategoriesHandler = () => {
  //   MainService.getCategoriesBasedOnParentId()
  //     .then((res) => {
  //       const catFilter = res.filter((resdata, index) => {
  //         return resdata.categorytypeid === "AGR11";
  //       });
  //       setCatId(catFilter[0].categoryid);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const categoryId = `${window.livasaConfig.categoryId}`
  const accessToken = localStorage.getItem("token");
  const user = `${window.livasaConfig.user}`;

  const getAllProductsofUser = useCallback(() => {
    
    MainService.getAllProducts(categoryId)
      .then((res) => {
        setHoneyProducts(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  },[categoryId])

  useEffect(() => {
    // getProductsHandler();
    getAllProductsofUser();
  }, [getAllProductsofUser]);
  const getAllSubCategory = useCallback(() => {
    // if(subCategory >0){
    //   setLoading(false);

    //   return
    // }
    if(!accessToken){
      return
    }
    MainService.getSubCategories(user, `${window.livasaConfig.categoryId}`)
      .then((res) => {
        setSubCategory(res.contentsubcategories);
        setLoading(false);
      })
      .catch((e) => {
        console.log("555555555555555e", e);
      });
  },[accessToken,user])
  useEffect(() => {
    // getProductsHandler();

      getAllSubCategory();
   
  },[getAllSubCategory]);

  // const getProductsHandler = () => {
  //   MainService.productModelBasedonSubcategory(
  //     getCatId,
  //     "Livasa-Natural-Honey",
  //     user
  //   )
  //     .then((res) => {
  //       setHoneyProducts(res);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("rrrrrrrrrrrrsssssssss", err);
  //     });
  // };

  const reviewData = [
    {
      image: user1,
      review:
        "I am using Livasa Shilajit from last 6 Months. I can see the Imapct of it on My Daily Routine and Sexual Power Both.",
      description: "Naresh Khanna - Age 44 - West Mumbai",
      ratings: "★★★★★",
    },
    {
      image: user2,
      review:
        "Me and my Husband are using the Livasa Kesar for last 3 months with Milk Every night for the better Health Being and Lifestyle.",
      description: "Narmata - Age - 32 - Delhi",
      ratings: "★★★★★",
    },
    {
      image: user3,
      review:
        "Livasa Jamun Honey and Neem Honey are the Best Switch over alternates for the Stage 1 and Stage 2 Diabetic Patient",
      description: "Amrit - Age 41 - Chandigarh",
      ratings: "★★★★☆",
    },
  ];

  var settings = {
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 1,

    responsive: [
      {
        initialSlide: 1,
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          prevArrow: false,
          nextArrow: false,
          arrows: false,
        },
      },
    ],
  };

 

  const subcategoryImageMapping = {
    "Processed-Honey": ProcessedHoney,
    "Semi-Processed-Honey": SemiProcessedHoney,
    "Raw-Honey": RawHoney,
  };
  const defaultImageUrl = RawHoney;
  return (
    <div>
      <Banner />
      <div className="section-container">
        <Title classname="text-center mb-4 mb-lg-5" heading="Collections" />
        <div className="container">
          <Row className="justify-content-center">
            {loding === false ? (
              <>
                {subCategory.map((data, index) => {
                  const imageUrl =
                    subcategoryImageMapping[data.contentsubcategoryname] ||
                    defaultImageUrl;
                  return (
                    <Col md={6} lg={3} key={index}>
                      <NavLink
                        key={data.contentsubcategoryname}
                        to={`/collections/products/${data.contentsubcategoryname}/#products`}
                      >
                        <div className="card-container">
                          <div className="card-image-container mb-4">
                            <img
                              src={imageUrl}
                              alt={data.contentsubcategoryname}
                            />
                          </div>
                          <div>
                            <h5 className="text-center">
                              {data.contentsubcategoryname}
                            </h5>
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  );
                })}
              </>
            ) : (
              <div className="justify-content-center d-flex">
                <Circles
                  type="TailSpin"
                  color="orange"
                  height={70}
                  width={70}
                  timeout={5000}
                />
              </div>
            )}
          </Row>
          {/* <CollectionContainer justify="true" /> */}
        </div>
      </div>

      <div className="section-container">
        <Title
          classname="text-center mb-lg-5 mb-3"
          heading="Best Seller Honey 🔥"
        />

        <div className="container ">
          <iframe
            className="video-container"
            title="vimeo-player"
            src="https://player.vimeo.com/video/722834425?h=f3a1212ad5"
            loading="lazy"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ border: "none" }}
          ></iframe>
        </div>
      </div>

      <div className="section-container">
        <div className="container">
          <div className="section-sub-container text-center">
            <Title name="text-center mb-4" heading="Livasa Natural Products" />
            <p className="mb-5">
              Livasa products are processed for a rich taste and quality.
            </p>
          </div>
          <Row>
            {loding === false ? (
              <>
                {getHoneyProducts.length !== 0 ? (
                  <Slider {...settings}>
                    {getHoneyProducts.map((e, index) => {
                      return (
                        <Col
                          key={index}
                          md={12}
                          lg={6}
                          sm={12}
                          xs={12}
                          className="px-3 justify-self-center"
                        >
                          <Products
                            src={e.productimageurl}
                            src1={e.qacertificateurl}
                            produtName={e.productfriendlyname}
                            discount={e.discountedprice}
                            productPrice={e.productcost}
                            origin={e.country}
                            url={e.infourl}
                            count={e.itemcount}
                            qr={e.productqrcodeurl}
                            likes={e.likes}
                            certificate={e.qacertificateurl}
                            model_id={e.productid}
                            likecount={e.likecount}
                            product={e}
                            buylink={e.buyurl}
                          />
                        </Col>
                      );
                    })}
                  </Slider>
                ) : (
                  <h3 className="title">Products Not Found</h3>
                )}
              </>
            ) : (
              <div className="justify-content-center d-flex">
                <Circles
                  type="TailSpin"
                  color="orange"
                  height={70}
                  width={70}
                  timeout={5000}
                />
              </div>
            )}
          </Row>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="section-container benefit-container">
        <div className="container">
          <div className="section-sub-container">
            <Title classname="text-center mb-4" heading="Benefits of Honey" />
            <p className=" text-center mb-5">
              Livasa Pure Honey is a natural antioxidant. Honey is often used to
              build immunity and acts as a pre-workout energizer. A spoonful of
              honey is a perfect remedy for:
            </p>
          </div>
        </div>
        <img src={Benefits} alt="Benefits" />
      </div>
      <div className="section-container mb-5">
        <Title classname="text-center mb-5" heading="Our Clients" />
        <div className="container">
          <Row className="justify-content-center">
            {clients.map((clientName, index) => {
              return (
                <Col key={index} md={3} className="d-flex ">
                  <div className="client-container mb-3 w-100 align-items-center">
                    {clientName}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      {/* <CTA /> */}
      {/* Our Clients Section */}
      <div className="section-container">
        <Title
          classname="text-center mb-5"
          heading="What People Say About Us"
        />
        <div className="container">
          <Row>
            {reviewData.map((e, index) => {
              return (
                <Col md={12} lg={4} key={index}>
                  <ReviewContainer
                    src={e.image}
                    reviews={e.review}
                    description={e.description}
                    ratings={e.ratings}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </div>
  );
}
