import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const center = {
  lat: 28.6448,
  lng: 77.216721,
};

function StoreLocator() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA08TJWvLGvDD4cXLo-231gioXgaugKUw0",
  });



  let markers = [
    // {
    //   name: "marker1",
    //   position: { lat: 28.17655, lng: 77.138564 },
    //   title: "Livasa Naturals",
    // },
    {
      name: "marker2",
      position: { lat:  28.6060, lng: 77.4297 },
      title: "Livasa Natural",
    },

    {
      name: "marker3",
      position: { lat: 13.038969, lng: 77.652069 },
      title: "Livasa Natural",
    },
  ];

  // const getMarkers = () =>{
  //   const data = MainService.getStoreLocation("Store")
  //   console.log(data, "OOOOOOOOOOOOOOOOOOOOOOOOOO")
  // }




  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4}>
      {markers.map((marker, index) => (
        <Marker
          key={index}
          name={marker.name}
          position={marker.position}
          title={marker.title}
        />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(StoreLocator);
