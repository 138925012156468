import React, { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./Navbar.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";

import Logo from "../../assets/images/new-images/MicrosoftTeams-image (8.1).png";

import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { AiFillTwitterSquare } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io";
const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <div>
      {click && <div className="menu-overlay"></div>}
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar p-0">
        <div className="nav-container container d-flex align-items-center justify-content-between">
          <div className="logo-container">
            <Link to="/">
              <img alt="Logo" src={Logo} />
            </Link>
          </div>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <RxCross1 /> : <RxHamburgerMenu />}
          </div>

          <div className={`${click ? "nav-menu active" : "nav-menu"} d-flex `}>
            <div className="d-flex justify-content-lg-end nav-item-container ">
              <ul className="d-flex mb-0 list-unstyled nav-rt">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    About Us
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/our-usp"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Our USP
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/collections/products/Processed-Honey"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Our Products
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Login
                  </NavLink>
                </li>
              </ul>

              <ul className="d-flex mb-0 list-unstyled  nav-lf">
                <li className="mobile-visible">
                  <a
                    rel="noopener noreferrer"
                    className="icon-container"
                    href="https://www.facebook.com/profile.php?id=100094453486891"
                    target="_blank"
                  >
                    <FaFacebookSquare />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://instagram.com/livasanatural.honey?igshid=MzNlNGNkZWQ4Mg=="
                    target="_blank"
                    className="icon-container"
                  >
                    <BsInstagram />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/livasa-natural/"
                    target="_blank"
                    className="icon-container"
                  >
                    <BsLinkedin />
                  </a>

                  <a
                    rel="noopener noreferrer"
                    href="https://instagram.com/livasanatural.honey?igshid=MzNlNGNkZWQ4Mg=="
                    target="_blank"
                    className="icon-container icon-container--modt"
                  >
                    <AiFillTwitterSquare />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    href="https://youtube.com/@livasanatural72?si=OyboV_F2rNhd3ciE"
                    target="_blank"
                    className="icon-container icon-container--mody"
                  >
                    <IoLogoYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
};

export default Navbar;
